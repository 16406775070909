<template>
  <!-- 个人信息 -->
  <div id="app">
    <div class="center-tit">
      个人基本信息
      <span>/Information</span>
    </div>
    <div class="account-con">
      <div class="infor-top">
        <img
          v-if="userInfo.avatar"
          :src="userInfo.avatar"
          class="logo"
          @click="dialogFormVisible = true"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <span @click="dialogFormVisible = true">更换头像</span>
      </div>
      <div class="form-con">
        <el-form
          ref="form"
          :inline="true"
          :model="form"
          class="demo-form-inline"
          label-width="80px"
        >
          <el-form-item label="性别">
            <el-radio-group v-model="form.gender" size="small">
              <el-radio border label="1">男</el-radio>
              <el-radio border label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="用户名">
            <el-input v-model="form.username" type="text"></el-input>
          </el-form-item>
          <el-form-item label="毕业院校">
            <el-select
              v-model="graduatedSchool"
              filterable
              remote
              placeholder="请搜索毕业院校"
              :remote-method="remoteMethod"
              :loading="loading"
              @change="changeSchool"
            >
              <el-option
                v-for="item in schools"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="最高学历">
            <el-select v-model="form.education">
              <el-option label="本科" value="本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士" value="博士"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入学时间">
            <el-date-picker
              v-model="form.admissionTime"
              type="date"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="所学专业">
            <el-select v-model="form.profession">
              <el-option
                label="CS及其相关专业"
                value="CS及其相关专业"
              ></el-option>
              <el-option
                label="EE及其相关专业"
                value="EE及其相关专业"
              ></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="个性签名" style="width: 100%">
            <el-input
              v-model="form.introduction"
              class="block"
              type="text"
            ></el-input>
          </el-form-item>
          <div class="form-tit">求职信息</div>
          <el-form-item label="工作经验">
            <el-input v-model="form.workExperience"></el-input>
          </el-form-item>
          <el-form-item label="所在公司">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="求职方向">
            <el-input v-model="form.jobSearchDirection"></el-input>
          </el-form-item>
          <el-form-item label="所在时区">
            <el-input v-model="form.timeZone"></el-input>
          </el-form-item>
          <el-form-item label="求职区域">
            <el-input v-model="form.jobSearchArea"></el-input>
          </el-form-item>
        </el-form>
        <div class="luo-submit">
          <div class="submit" @click="onSubmit">保存修改</div>
        </div>
      </div>
    </div>
    <div v-if="dialogFormVisible" class="submit-con">
      <div class="box set-logo">
        <div class="name">更改头像</div>
        <div class="logos-con">
          <el-upload
            :before-upload="beforeAvatarUpload"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :action="uploadUrl"
            class="avatar-uploader"
          >
            <img v-if="currentAvatar" :src="currentAvatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <p>点击头像更换</p>
          </el-upload>
        </div>
        <div class="btns">
          <button @click="onConfirmUpload">确定</button>
          <button class="cancel" @click="onCancleUpload">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { updateUserInfo } from "../../../service/index";
import { getSchoolList } from "@/service/user";
import { getIn } from "../../../utils/index";
import { mapState } from "vuex";
import { Loading } from "element-ui";
import _ from "lodash";
export default {
  data() {
    return {
      loading: false,
      schools: [],
      graduatedSchool: "",
      form: {
        admissionTime: "", // 入学时间
        avatar: "", // 头像
        company: "", // 所在公司
        education: "", // 最高学历
        gender: "", // 性别，1：男，2：女,可用值:FEMALE,MALE,UNKNOWN
        graduatedSchool: "", // 毕业院校
        introduction: "", // 个人签名
        jobSearchArea: "", // 求职区域
        jobSearchDirection: "", // 求职方向
        profession: "", // 所学专业
        realName: "", // 真实姓名
        status: 0, // 状态，1：学生，2：在职
        timeZone: "", // 所在时区
        userId: 0, // 用户id
        workExperience: "", // 工作经验
      },
      dialogFormVisible: false,
      imageUrl: require("@/assets/center/user-logo1.png"),
      avatar: "",
      isLoading: false,
      headers: {},
      currentAvatar: "",
      objectKey: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
    };
  },
  computed: mapState(["userInfo"]),
  watch: {
    userInfo() {
      this.getPersonInfo();
    },
  },
  async created() {
    console.log(this.userInfo);
    await this.$store.dispatch("queryUserInfo");
  },
  mounted() {
    this.path = this.$route.path;
    this.getPersonInfo();
  },
  methods: {
    changeSchool(e) {
      console.log(e);
      this.form.graduatedSchool = e;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        console.log(query);
        var that = this;
        getSchoolList({ name: query }).then((res) => {
          this.loading = false;
          this.schools = res.result.map((item) => {
            return {
              value: item.id,
              label: item.nameCn || item.nameEn,
            };
          });
        });
      } else {
        this.options = [];
      }
    },
    async getPersonInfo() {
      this.graduatedSchool =
        this.userInfo.graduatedSchoolNameCn ||
        this.userInfo.graduatedSchoolNameEn ||
        this.userInfo.graduatedSchoolNameAbbr;
      this.form = {
        gender: getIn(this.userInfo, ["gender", "value"]) == 1 ? "1" : "2",
        graduatedSchool: this.userInfo.graduatedSchool,
        education: this.userInfo.education || "",
        admissionTime: this.userInfo.admissionTime || "",
        profession: this.userInfo.profession || "",
        introduction: this.userInfo.introduction || "",
        workExperience: this.userInfo.workExperience || "",
        company: this.userInfo.company || "",
        jobSearchDirection: this.userInfo.jobSearchDirection || "",
        timeZone: this.userInfo.timeZone || "",
        jobSearchArea: this.userInfo.jobSearchArea || "",
        avatar: this.userInfo.avatar || "",
        userId: this.userInfo.id,
        username: this.userInfo.username,
      };
      this.currentAvatar = this.userInfo.avatar || "";
      this.headers = {
        "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
      };
    },

    tabNav(index) {
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 提交修改
    async onSubmit({ avatar }) {
      if (this.isLoading) {
        return;
      }
      const loadingInstance = Loading.service({
        fullscreen: false,
        lock: true,
        text: "加载中...",
      });
      this.isLoading = true;
      const params = this.form;
      delete params.avatar;
      if (avatar) {
        params.avatar = avatar;
      }
      const result = await updateUserInfo(params);
      this.isLoading = false;
      loadingInstance.close();
      const success = getIn(result, ["success"]);
      const message = getIn(result, ["message"]) || "系统繁忙，请稍后再试";
      console.log("------", result);
      if (success) {
        this.$message.closeAll();
        this.$message.success("更新成功");
        this.$store.dispatch("queryUserInfo");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$message.closeAll();
        this.$message.error(message);
      }
      return result;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    async handleAvatarSuccess(e) {
      const avatar = getIn(e, ["result", "accessibleUrl"]);
      const objectKey = getIn(e, ["result", "objectKey"]);
      const message = getIn(e, ["message"]) || "系统繁忙，请稍后再试";
      if (avatar && objectKey) {
        this.currentAvatar = avatar;
        this.objectKey = objectKey;
      } else {
        this.$message.closeAll();
        this.$message.error(message);
      }
    },
    async onConfirmUpload() {
      if (!this.objectKey) {
        this.dialogFormVisible = false;
        return;
      }
      await this.onSubmit({ avatar: this.objectKey });
      this.form.avatar = this.currentAvatar;
      this.dialogFormVisible = false;
      this.objectKey = "";
    },
    onCancleUpload() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped>
.save-update {
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
.luo-submit {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
